export const strings = { 
    // Header Menu Items
    CMS_MENU_HOME: 'menu.home',
    CMS_MENU_HOW: 'menu.how',
    CMS_MENU_FIELDS: 'menu.fields',
    CMS_MENU_GIFT_CARDS: 'menu.gift_cards',
    CMS_MENU_START: 'menu.start',
    CMS_MENU_PARTIES: 'menu.parties',
    CMS_LOGIN: 'login',
    CMS_REGISTER: 'register',
    CMS_BOOK_NOW: 'book_now',
    
    // User Menu
    CMS_MENU_PROFILE: 'menu.profile',
    CMS_MENU_FAQS: 'menu.faqs',
    CMS_MENU_DOGS: 'menu.dogs',
    CMS_MENU_BOOKINGS: 'menu.bookings',
    CMS_MENU_PAYMENT_METHODS: 'menu.payment_methods',
    CMS_MENU_GO_TO_ADMIN: 'menu.go_to_admin',
    CMS_MENU_GO_TO_FRANCHISE: 'menu.go_to_franchise',
    CMS_MENU_LOGOUT: 'menu.logout',
    
    // Footer Content
    CMS_FOOTER_STRAPLINE: 'footer.strapline',
    CMS_FOOTER_LOCATIONS: 'footer.locations',
    CMS_FOOTER_COMPANY: 'footer.company',
    CMS_FOOTER_CONTACT: 'footer.contact',
    CMS_FOOTER_ABOUT_US: 'footer.about_us',
    CMS_FOOTER_NEWSLETTER_TITLE: 'footer.newsletter_title',
    CMS_FOOTER_NEWSLETTER_SUBTITLE: 'footer.newsletter_subtitle',
    CMS_FOOTER_NEWSLETTER_NAME: 'footer.newsletter_name',
    CMS_FOOTER_NEWSLETTER_EMAIL: 'footer.newsletter_email',
    CMS_FOOTER_NEWSLETTER_SUBMIT: 'footer.newsletter_submit',
    CMS_FOOTER_NEWSLETTER_SUBMITTING: 'footer.newsletter_submitting',
    CMS_FOOTER_NEWSLETTER_SUCCESS: 'footer.newsletter_success',
    CMS_FOOTER_NEWSLETTER_ERROR: 'footer.newsletter_error',
    CMS_FOOTER_PRIVACY: 'footer.privacy',
    CMS_FOOTER_TERMS: 'footer.terms',
    CMS_FOOTER_LEGAL: 'footer.legal',
    CMS_FOOTER_NOISE: 'footer.noise',

    // Hero Component
    CMS_HERO_TITLE: 'hero.title',
    CMS_HERO_SUBTITLE: 'hero.subtitle',
    CMS_HERO_BUTTON: 'hero.button',

    // How It Works Component
    CMS_HOW_IT_WORKS_CALLOUT: 'how_it_works.callout',

    // How Steps Component
    CMS_HOW_STEPS_ONE_TITLE: 'how_steps.one.title',
    CMS_HOW_STEPS_ONE_DESCRIPTION: 'how_steps.one.description',
    CMS_HOW_STEPS_TWO_TITLE: 'how_steps.two.title',
    CMS_HOW_STEPS_TWO_DESCRIPTION: 'how_steps.two.description',
    CMS_HOW_STEPS_THREE_TITLE: 'how_steps.three.title',
    CMS_HOW_STEPS_THREE_DESCRIPTION: 'how_steps.three.description',
    CMS_HOW_STEPS_FOUR_TITLE: 'how_steps.four.title',
    CMS_HOW_STEPS_FOUR_DESCRIPTION: 'how_steps.four.description',
    CMS_HOW_STEPS_FIVE_TITLE: 'how_steps.five.title',
    CMS_HOW_STEPS_FIVE_DESCRIPTION: 'how_steps.five.description',
    CMS_HOW_STEPS_SIX_TITLE: 'how_steps.six.title',
    CMS_HOW_STEPS_SIX_DESCRIPTION: 'how_steps.six.description',

    CMS_HOW_MESSAGE_TITLE: 'how_message.title',
    CMS_HOW_MESSAGE_DESCRIPTION: 'how_message.description',
    CMS_HOW_MESSAGE_EMPHASIS: 'how_message.emphasis',
    
    // Fields Grid Component
    CMS_FIELDS_TITLE: 'fields.title',
    CMS_FIELDS_FROM: 'fields.from',
    CMS_FIELDS_BOOK_NOW: 'fields.book_now',
    
    // FieldDetails Component
    CMS_FIELD_DETAILS_DESCRIPTION: 'field_details.description',
    CMS_FIELD_DETAILS_FEATURES: 'field_details.features',
    CMS_FIELD_DETAILS_LOCATION: 'field_details.location',
    CMS_FIELD_DETAILS_ADDRESS: 'field_details.address',
    CMS_FIELD_DETAILS_DIRECTIONS: 'field_details.directions',
    CMS_FIELD_DETAILS_BOOKING: 'field_details.booking',
    CMS_FIELD_DETAILS_PRICES: 'field_details.prices',
    CMS_FIELD_DETAILS_PRICE_PER_HOUR: 'field_details.price_per_hour',
    CMS_FIELD_DETAILS_CHECK_AVAILABILITY: 'field_details.check_availability',
    
    // FieldHeader Component
    CMS_FIELD_HEADER_STARTING_AT: 'field_header.starting_at',
    CMS_FIELD_HEADER_BOOK_NOW: 'field_header.book_now',
    
    // FieldInformation Component
    CMS_FIELD_INFO_TITLE: 'field_info.title',
    CMS_FIELD_INFO_GETTING_DIRECTIONS: 'field_info.getting_directions',
    CMS_FIELD_INFO_HIDE_DIRECTIONS: 'field_info.hide_directions',
    CMS_FIELD_INFO_SHOW_DIRECTIONS: 'field_info.show_directions',
    CMS_FIELD_INFO_GET_DIRECTIONS: 'field_info.get_directions',
    CMS_FIELD_INFO_DIRECTIONS_TITLE: 'field_info.directions_title',
    CMS_FIELD_INFO_WHAT3WORDS: 'field_info.what3words',
    CMS_FIELD_INFO_OPENING_TIMES: 'field_info.opening_times',
    CMS_FIELD_INFO_CLOSED: 'field_info.closed',
    
    // FieldsGridLarge Component
    CMS_FIELDS_GRID_LARGE_SEARCH_PLACEHOLDER: 'fields_grid_large.search_placeholder',
    CMS_FIELDS_GRID_LARGE_SEARCH_BUTTON: 'fields_grid_large.search_button',
    CMS_FIELDS_GRID_LARGE_LOADING: 'fields_grid_large.loading',
    CMS_FIELDS_GRID_LARGE_NO_FIELDS: 'fields_grid_large.no_fields',
    CMS_FIELDS_GRID_LARGE_BOOK_NOW: 'fields_grid_large.book_now',
    CMS_FIELDS_GRID_LARGE_FROM: 'fields_grid_large.from',
    
    // FieldsMap Component
    CMS_FIELDS_MAP_BOOK_NOW: 'fields_map.book_now',
    
    // BookingForm Component
    CMS_BOOKING_TITLE: 'booking.title',
    CMS_BOOKING_SELECT_DATE: 'booking.select_date',
    CMS_BOOKING_SELECT_TIME: 'booking.select_time',
    CMS_BOOKING_SELECT_DURATION: 'booking.select_duration',
    CMS_BOOKING_HOUR: 'booking.hour',
    CMS_BOOKING_HOURS: 'booking.hours',
    CMS_BOOKING_AVAILABLE: 'booking.available',
    CMS_BOOKING_UNAVAILABLE: 'booking.unavailable',
    CMS_BOOKING_CONTINUE: 'booking.continue',
    
    // WhyUs Component
    CMS_WHY_US_TITLE: 'why_us.title',
    CMS_WHY_US_SUBTITLE: 'why_us.subtitle',
    CMS_WHY_US_REASON1_TITLE: 'why_us.reason1_title',
    CMS_WHY_US_REASON1_TEXT: 'why_us.reason1_text',
    CMS_WHY_US_REASON2_TITLE: 'why_us.reason2_title',
    CMS_WHY_US_REASON2_TEXT: 'why_us.reason2_text',
    CMS_WHY_US_REASON3_TITLE: 'why_us.reason3_title',
    CMS_WHY_US_REASON3_TEXT: 'why_us.reason3_text',
    
    // WhyUs Features
    CMS_WHY_US_FEATURE1_TITLE: 'why_us.feature1_title',
    CMS_WHY_US_FEATURE1_DESC: 'why_us.feature1_desc',
    CMS_WHY_US_FEATURE2_TITLE: 'why_us.feature2_title',
    CMS_WHY_US_FEATURE2_DESC: 'why_us.feature2_desc',
    CMS_WHY_US_FEATURE3_TITLE: 'why_us.feature3_title',
    CMS_WHY_US_FEATURE3_DESC: 'why_us.feature3_desc',
    CMS_WHY_US_FEATURE4_TITLE: 'why_us.feature4_title',
    CMS_WHY_US_FEATURE4_DESC: 'why_us.feature4_desc',
    CMS_WHY_US_FEATURE5_TITLE: 'why_us.feature5_title',
    CMS_WHY_US_FEATURE5_DESC: 'why_us.feature5_desc',
    CMS_WHY_US_FEATURE6_TITLE: 'why_us.feature6_title',
    CMS_WHY_US_FEATURE6_DESC: 'why_us.feature6_desc',
    
    // ThingsToRemember Component
    CMS_REMEMBER_TITLE: 'remember.title',
    CMS_REMEMBER_ITEM1_TITLE: 'remember.item1_title',
    CMS_REMEMBER_ITEM1_DESC: 'remember.item1_desc',
    CMS_REMEMBER_ITEM2_TITLE: 'remember.item2_title',
    CMS_REMEMBER_ITEM2_DESC: 'remember.item2_desc',
    CMS_REMEMBER_ITEM3_TITLE: 'remember.item3_title',
    CMS_REMEMBER_ITEM3_DESC: 'remember.item3_desc',
    CMS_REMEMBER_ITEM4_TITLE: 'remember.item4_title',
    CMS_REMEMBER_ITEM4_DESC: 'remember.item4_desc',
    CMS_REMEMBER_ITEM5_TITLE: 'remember.item5_title',
    CMS_REMEMBER_ITEM5_DESC: 'remember.item5_desc',
    CMS_REMEMBER_ITEM6_TITLE: 'remember.item6_title',
    CMS_REMEMBER_ITEM6_DESC: 'remember.item6_desc',
    
    // PriceCalculator Component
    CMS_PRICE_CALCULATOR_TITLE: 'price_calculator.title',
    CMS_PRICE_CALCULATOR_SELECT_FIELD: 'price_calculator.select_field',
    CMS_PRICE_CALCULATOR_CHOOSE_FIELD: 'price_calculator.choose_field',
    CMS_PRICE_CALCULATOR_NUMBER_DOGS: 'price_calculator.number_dogs',
    CMS_PRICE_CALCULATOR_ESTIMATED_PRICE: 'price_calculator.estimated_price',
    CMS_PRICE_CALCULATOR_NOTE: 'price_calculator.note',
    
    // StartForm Component
    CMS_START_FORM_TITLE: 'start_form.title',
    CMS_START_FORM_FULL_NAME: 'start_form.full_name',
    CMS_START_FORM_EMAIL: 'start_form.email',
    CMS_START_FORM_PHONE: 'start_form.phone',
    CMS_START_FORM_LONGITUDE: 'start_form.longitude',
    CMS_START_FORM_LATITUDE: 'start_form.latitude',
    CMS_START_FORM_WHAT3WORDS: 'start_form.what3words',
    CMS_START_FORM_DESCRIPTION: 'start_form.description',
    CMS_START_FORM_DRAG_DROP: 'start_form.drag_drop',
    CMS_START_FORM_SELECT_FILES: 'start_form.select_files',
    CMS_START_FORM_FILES_SELECTED: 'start_form.files_selected',
    CMS_START_FORM_REGISTER: 'start_form.register',
    CMS_START_FORM_REASONS_TITLE: 'start_form.reasons_title',
    CMS_START_FORM_REASON1: 'start_form.reason1',
    CMS_START_FORM_REASON2: 'start_form.reason2',
    CMS_START_FORM_REASON3: 'start_form.reason3',
    CMS_START_FORM_REASON4: 'start_form.reason4',
    
    // Banner Component
    CMS_BANNER_TEXT: 'banner.text',
    
    // Parties Page
    CMS_PARTIES_TITLE: 'parties.title',
    CMS_PARTIES_SUBTITLE: 'parties.subtitle',
    CMS_PARTIES_CALLOUT: 'parties.callout',
    CMS_PARTIES_MAKE_PARTY: 'parties.make_party',
    CMS_PARTIES_NEXT_BOOKING: 'parties.next_booking',
    CMS_PARTIES_MAKE_PARTY_BUTTON: 'parties.make_party_button',
    
    // Party Benefits
    CMS_PARTY_BENEFIT1_TITLE: 'party.benefit1_title',
    CMS_PARTY_BENEFIT1_DESC: 'party.benefit1_desc',
    CMS_PARTY_BENEFIT2_TITLE: 'party.benefit2_title',
    CMS_PARTY_BENEFIT2_DESC: 'party.benefit2_desc',
    CMS_PARTY_BENEFIT3_TITLE: 'party.benefit3_title',
    CMS_PARTY_BENEFIT3_DESC: 'party.benefit3_desc',
    CMS_PARTY_BENEFIT4_TITLE: 'party.benefit4_title',
    CMS_PARTY_BENEFIT4_DESC: 'party.benefit4_desc',
    CMS_PARTY_BENEFIT5_TITLE: 'party.benefit5_title',
    CMS_PARTY_BENEFIT5_DESC: 'party.benefit5_desc',
    CMS_PARTY_BENEFIT6_TITLE: 'party.benefit6_title',
    CMS_PARTY_BENEFIT6_DESC: 'party.benefit6_desc',
    
    // Party Form
    CMS_PARTY_FORM_TITLE: 'party_form.title',
    CMS_PARTY_FORM_TYPE: 'party_form.type',
    CMS_PARTY_FORM_TYPE_BIRTHDAY: 'party_form.type_birthday',
    CMS_PARTY_FORM_TYPE_PLAYDATE: 'party_form.type_playdate',
    CMS_PARTY_FORM_TYPE_SOCIAL: 'party_form.type_social',
    CMS_PARTY_FORM_BIRTHDAY_DOG_NAME: 'party_form.birthday_dog_name',
    CMS_PARTY_FORM_BIRTHDAY_DOG_AGE: 'party_form.birthday_dog_age',
    CMS_PARTY_FORM_SOCIAL_TITLE: 'party_form.social_title',
    CMS_PARTY_FORM_SOCIAL_DESC: 'party_form.social_desc',
    CMS_PARTY_FORM_GUESTS: 'party_form.guests',
    CMS_PARTY_FORM_ADD_GUEST: 'party_form.add_guest',
    CMS_PARTY_FORM_GUEST_NAME: 'party_form.guest_name',
    CMS_PARTY_FORM_GUEST_EMAIL: 'party_form.guest_email',
    CMS_PARTY_FORM_SUBMIT: 'party_form.submit',
    CMS_PARTY_FORM_CANCEL: 'party_form.cancel',
    CMS_PARTY_FORM_PROCESSING: 'party_form.processing',
    
    // FAQ Component
    CMS_FAQ_CATEGORIES_TITLE: 'faq.categories_title',
    CMS_FAQ_SHOW_ALL: 'faq.show_all',
    CMS_FAQ_FEATURED_TITLE: 'faq.featured_title',
    CMS_FAQ_SEARCH_PLACEHOLDER: 'faq.search_placeholder',
    
    // NewBookingForm Component
    CMS_BOOKING_FORM_TITLE: 'booking_form.title',
    CMS_BOOKING_FORM_FIELD_SECTION: 'booking_form.field_section',
    CMS_BOOKING_FORM_FIELD_DESC: 'booking_form.field_desc',
    CMS_BOOKING_FORM_ABOUT_YOU: 'booking_form.about_you',
    CMS_BOOKING_FORM_ABOUT_YOU_DESC: 'booking_form.about_you_desc',
    CMS_BOOKING_FORM_FULL_NAME: 'booking_form.full_name',
    CMS_BOOKING_FORM_EMAIL: 'booking_form.email',
    CMS_BOOKING_FORM_PHONE: 'booking_form.phone',
    CMS_BOOKING_FORM_PHONE_DESC: 'booking_form.phone_desc',
    CMS_BOOKING_FORM_DOGS: 'booking_form.dogs',
    CMS_BOOKING_FORM_DOGS_DESC: 'booking_form.dogs_desc',
    CMS_BOOKING_FORM_NUMBER_OF_DOGS: 'booking_form.number_of_dogs',
    CMS_BOOKING_FORM_DOG_CERT: 'booking_form.dog_certification',
    CMS_BOOKING_FORM_MORE_ABOUT_DOGS: 'booking_form.more_about_dogs',
    CMS_BOOKING_FORM_YOUR_SLOT: 'booking_form.your_slot',
    CMS_BOOKING_FORM_YOUR_SLOT_DESC: 'booking_form.your_slot_desc',
    CMS_BOOKING_FORM_BOOKING_DATE: 'booking_form.booking_date',
    CMS_BOOKING_FORM_BOOKING_LENGTH: 'booking_form.booking_length',
    CMS_BOOKING_FORM_ADDITIONAL_SLOTS: 'booking_form.additional_slots',
    CMS_BOOKING_FORM_DISCOUNT_CODE: 'booking_form.discount_code',
    CMS_BOOKING_FORM_DISCOUNT_CODE_DESC: 'booking_form.discount_code_desc',
    CMS_BOOKING_FORM_DISCOUNT_CODE_RECURRING: 'booking_form.discount_code_recurring',
    CMS_BOOKING_FORM_GIFT_CARD: 'booking_form.gift_card',
    CMS_BOOKING_FORM_GIFT_CARD_DESC: 'booking_form.gift_card_desc',
    CMS_BOOKING_FORM_TERMS: 'booking_form.terms',
    CMS_BOOKING_FORM_NEWSLETTER: 'booking_form.newsletter',
    CMS_BOOKING_FORM_SUMMARY: 'booking_form.summary',
    CMS_BOOKING_FORM_CONFIRM_PAY: 'booking_form.confirm_pay',
    
    // PaymentProcessingDialog Component
    CMS_PAYMENT_PROCESSING_TITLE: 'payment_processing.title',
    CMS_PAYMENT_PROCESSING_WAIT: 'payment_processing.wait',
    CMS_PAYMENT_CONFIRMED_TITLE: 'payment_processing.confirmed_title',
    CMS_PAYMENT_CONFIRMED_MESSAGE: 'payment_processing.confirmed_message',
    CMS_PAYMENT_REDIRECTING: 'payment_processing.redirecting',
    CMS_PAYMENT_GIFT_CARD_EMAIL: 'payment_processing.gift_card_email',
    CMS_PAYMENT_ERROR_TITLE: 'payment_processing.error_title',
    CMS_PAYMENT_ERROR_MESSAGE: 'payment_processing.error_message',
    
    // Notes Component
    CMS_NOTES_TITLE: 'notes.title',
    CMS_NOTES_APPLIES: 'notes.applies',
    
    // RateCard Component
    CMS_RATE_CARD_PRICING: 'rate_card.pricing',
    CMS_RATE_CARD_DISCLAIMER: 'rate_card.disclaimer',
    CMS_RATE_CARD_NOTE: 'rate_card.note',
    CMS_RATE_CARD_SESSION_LENGTH: 'rate_card.session_length',
    CMS_RATE_CARD_DOGS: 'rate_card.dogs',
    CMS_RATE_CARD_PRICE: 'rate_card.price',

    // Block Names
    CMS_BLOCK_HERO: 'hero',
    CMS_BLOCK_WHY_US: 'why-why-section',
    CMS_BLOCK_HOW_IT_WORKS: 'how-it-works',
    CMS_BLOCK_HOW_STEPS: 'how-steps-section',
    CMS_BLOCK_CAR_PARK: 'car-park',
    CMS_BLOCK_PARTIES: 'parties-header',
    CMS_BLOCK_PARTY_BENEFITS: 'party-benefits',
} as const;

export type CmsStringKey = keyof typeof strings;
export type CmsStringValue = typeof strings[CmsStringKey];

// Base CMS Block interface
export interface CmsBlock {
    name: string;
    type: string;
    slug: string;
    content: Record<string, any>;
}

// Block type definitions with simplified content structure
export interface CmsHeaderBlock extends CmsBlock {
    type: 'App\\Blocks\\Header';
    content: {
        title: string;
        subtitle: string;
        button: string;
        image: string;
    };
}

export interface CmsCollectionBlock extends CmsBlock {
    type: 'App\\Blocks\\Collection';
    content: {
        items: CmsBlock[];
    };
}

export interface CmsIconBoxBlock extends CmsBlock {
    type: 'App\\Blocks\\IconBox';
    content: {
        icon: string;
        title: string;
        text: string;
    };
}

export interface CmsPageHeaderBlock extends CmsBlock {
    type: 'App\\Blocks\\PageHeader';
    content: {
        title: string;
        image: string;
    };
}

// Helper types for block retrieval
export type CmsBlocks = Record<string, CmsBlock>;

// Helper functions for type-safe block access
export function getHeaderBlock(blocks: CmsBlocks, slug: string): CmsHeaderBlock | null {
    const block = blocks[slug];
    if (block && block.type === 'App\\Blocks\\Header') {
        return block as CmsHeaderBlock;
    }
    return null;
}

export function getCollectionBlock(blocks: CmsBlocks, slug: string): CmsCollectionBlock | null {
    const block = blocks[slug];
    if (block && block.type === 'App\\Blocks\\Collection') {
        return block as CmsCollectionBlock;
    }
    return null;
}

export function getIconBoxBlock(blocks: CmsBlocks, slug: string): CmsIconBoxBlock | null {
    const block = blocks[slug];
    if (block && block.type === 'App\\Blocks\\IconBox') {
        return block as CmsIconBoxBlock;
    }
    return null;
}

export function getPageHeaderBlock(blocks: CmsBlocks, slug: string): CmsPageHeaderBlock | null {
    const block = blocks[slug];
    if (block && block.type === 'App\\Blocks\\PageHeader') {
        return block as CmsPageHeaderBlock;
    }
    return null;
}

// Generic function to get any block with type checking
export function getBlock<T extends CmsBlock>(blocks: CmsBlocks, slug: string): T | null {
    const block = blocks[slug];
    if (block) {
        return block as T;
    }
    return null;
}
